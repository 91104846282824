import { TextField as MaterialTextField, OutlinedTextFieldProps } from "@mui/material";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { blackTheme } from "@shared/utilities/theme";
import classNames from "classnames";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
  });

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  variant?: "filled" | "standard" | "outlined";
}

function TextField({ className, classes, variant, ...restProps }: TextFieldProps & WithStyles<typeof styles>) {
  return (
    <ThemeProvider theme={blackTheme}>
      <MaterialTextField
        {...restProps}
        className={classNames(classes.root, className)}
        variant={variant || "outlined"}
      />
    </ThemeProvider>
  );
}

export default withStyles(styles)(TextField);
