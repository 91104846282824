import deepFreeze from "deep-freeze";
import castAs from "shared/utilities/lang/castAs";
import Money from "shared/utilities/Money";
import { TimeZone } from "shared/utilities/Time";

export const FORMATS = deepFreeze({
  dateTime: castAs<{ [Key: string]: Partial<Intl.DateTimeFormatOptions> }>({
    longUtc: {
      // E.g. in US-EN: "07/17/2018, 14:03:44 UTC"
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
      timeZone: "UTC",
      hour12: false,
    },
    dateUtc: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC",
    },
    dateTime: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
    dateTimeUtc: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
      hour12: false,
    },
    date: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    },
    dateWithMonth: {
      year: "2-digit",
      month: "short",
      day: "2-digit",
    },
    hoursAndMinutes: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
  }),
  number: castAs<{ [Key: string]: Partial<Intl.NumberFormatOptions> }>({
    default: {},
    percent: { style: "percent" },
    percentWithDecimalDigit: { style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 1 },
    percentWithTwoDecimalDigits: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 },
    percentWithFourDecimalDigits: { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 4 },
    short: {
      maximumFractionDigits: 1,
    },
  }),
  money: {
    default: {
      maximumFractionDigits: 3,
    },
    short: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
});

export type DateTimeFormats = keyof (typeof FORMATS)["dateTime"];
export type NumberFormats = keyof (typeof FORMATS)["number"];
export type MoneyFormats = keyof (typeof FORMATS)["money"];

function fromCache<Value>(cache: Record<string, Value>, key: string, computeCachedValue: () => Value) {
  if (!cache[key]) {
    cache[key] = computeCachedValue();
  }

  return cache[key];
}

type LocaleFormatNumberFunction = (value: number, format?: NumberFormats) => string;
type LocaleFormatDateTimeFunction = (value: Date, format?: DateTimeFormats) => string;
type LocaleFormatMoneyFunction = (value: Money, format?: MoneyFormats) => string;
type LocaleFormatFunction = (value: FormattableValue) => string;
export interface ILocaleFormatFunctions {
  format: LocaleFormatFunction;
  formatNumber: LocaleFormatNumberFunction;
  formatDateTime: LocaleFormatDateTimeFunction;
  formatMoney: LocaleFormatMoneyFunction;
}

export type TemplateTagFunction = (
  stringLiterals: TemplateStringsArray,
  ...interpolatedValues: FormattableValue[]
) => string;

type FormattableValue = undefined | null | boolean | string | Date | number | Money;

export type TranslationDefinition<Definition> = (
  t: TemplateTagFunction,
  { format, formatNumber, formatDateTime, formatMoney }: ILocaleFormatFunctions,
) => Definition;

interface LocalFormattingProps {
  decimalsSeparator: string;
  thousandsSeparator: string;
}

type DateRange = { startDate: Date | null; endDate: Date | null };

type DateRangeFormatOptions = {
  format?: DateTimeFormats;
  timezone?: TimeZone;
  divider?: string;
};

export default class I18n<Definition> {
  public readonly translations: Definition;
  public readonly locale: string;

  private dateFormatCache: Record<string, Intl.DateTimeFormat>;
  private numberFormatCache: Record<string, Intl.NumberFormat>;

  constructor({
    locale,
    languages,
    fallbackLocale,
  }: {
    locale: string;
    languages: { [Key: string]: TranslationDefinition<Definition> };
    fallbackLocale: string;
  }) {
    this.dateFormatCache = {};
    this.numberFormatCache = {};

    this.locale = locale;

    let language = this.getClosestMatchingLanguage(locale, languages);
    if (!language) {
      this.locale = fallbackLocale;
      language = languages[fallbackLocale];
    }

    this.translations = language(this.translateValue, this);
  }

  private translateValue = (
    stringLiterals: TemplateStringsArray,
    ...interpolatedValues: FormattableValue[]
  ): string => {
    const formattedValues = interpolatedValues.map(value => this.format(value));
    return stringLiterals
      .reduce((result, literal, index) => {
        result.push(literal, formattedValues[index]);
        return result;
      }, [] as string[])
      .join("");
  };

  formatNumber = (value: number, format: NumberFormats = "default"): string => {
    const formatter = fromCache(
      this.numberFormatCache,
      `number|${format}`,
      () => new Intl.NumberFormat(this.locale, FORMATS.number[format]),
    );
    if (format === "percent" && value > 0 && value < 0.01) {
      return `<${formatter.format(0.01)}`;
    }

    return formatter.format(value);
  };

  formatMoney = (value: Money, format: MoneyFormats = "default", maximumFractionDigits: number = 2): string => {
    const formatter = fromCache(this.numberFormatCache, `money|${format}|${value.currencyCode}`, () => {
      const currencyOptions = { style: "currency", currency: value.currencyCode };
      const moneyFormatOptions = {
        ...FORMATS.money[format],
        ...currencyOptions,
        minimumFractionDigits: 0,
        maximumFractionDigits,
      };
      return new Intl.NumberFormat(this.locale, moneyFormatOptions);
    });
    return formatter.format(value.value);
  };

  formatDateTime = (value: Date, format: DateTimeFormats = "longUtc", timeZone: TimeZone = "UTC"): string => {
    const formatter = fromCache(
      this.dateFormatCache,
      `dateTime|${timeZone}|${format}`,
      () => new Intl.DateTimeFormat(this.locale, { ...FORMATS.dateTime[format], timeZone }),
    );
    let formatted = formatter.format(value);

    // TODO: remove this workaround once Intl.Locale.prototype.hourCycle is available in all supported browsers
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Locale/hourCycle
    if (format === "longUtc" || format === "dateTime" || format === "dateTimeUtc") {
      formatted = formatted.replace("24:00", "00:00");
    }
    return formatted;
  };

  formatDateRange = (
    { startDate, endDate }: DateRange,
    { format = "dateWithMonth", timezone = "UTC", divider = "-" }: DateRangeFormatOptions = {},
  ): string => {
    if (!startDate && !endDate) return "";

    const formatDate = (date: Date | null) => (date ? this.formatDateTime(date, format, timezone) : "...");

    return [startDate, endDate].map(formatDate).join(` ${divider} `);
  };

  // e.g. video_loop -> Video Loop
  humanizeString = (value: string): string => {
    return value.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()).replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());
  };

  format(value: FormattableValue): string {
    if (value === undefined || value === null) {
      return "";
    }
    if (value instanceof Date) {
      return this.formatDateTime(value);
    }
    if (typeof value === "number") {
      return this.formatNumber(value);
    }
    if (value instanceof Money) {
      return this.formatMoney(value);
    }
    return value.toString();
  }

  getDateFormatString() {
    const formatObj = new Intl.DateTimeFormat(this.locale).formatToParts(new Date());

    return formatObj
      .map(obj => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  getDatePickerInputFormatString() {
    const formatObj = new Intl.DateTimeFormat(this.locale).formatToParts(new Date());

    return formatObj
      .map(obj => {
        switch (obj.type) {
          case "day":
            return "dd";
          case "month":
            return "MM";
          case "year":
            return "yyyy";
          default:
            return obj.value;
        }
      })
      .join("");
  }

  getLocaleDecimalAndThousandsSeparator(locale: string): LocalFormattingProps {
    const numberWithDecimalSeparator = 1000.1;
    const decimalsSeparator = Intl.NumberFormat(locale)
      .formatToParts(numberWithDecimalSeparator)
      .find(part => part.type === "decimal")!.value;
    return { decimalsSeparator, thousandsSeparator: decimalsSeparator === "," ? "." : "," };
  }

  removeFormat(stringNumber: string, locale: string): string {
    const { decimalsSeparator, thousandsSeparator } = this.getLocaleDecimalAndThousandsSeparator(locale);
    const numberWithoutDecimalsSeparator = stringNumber.replace(new RegExp(`\\${thousandsSeparator}`, "g"), "");
    const numberWithDotDecimalSeparator = numberWithoutDecimalsSeparator.replace(
      new RegExp(`\\${decimalsSeparator}`),
      ".",
    );

    return numberWithDotDecimalSeparator;
  }

  private getClosestMatchingLanguage<Definition>(
    languageTag: string,
    languages: { [Key: string]: TranslationDefinition<Definition> },
  ): TranslationDefinition<Definition> | undefined {
    if (languageTag === "") {
      return undefined;
    }
    if (languages[languageTag]) {
      return languages[languageTag];
    }
    const nextLanguageTag = languageTag.split("-").slice(0, -1).join("-");
    return this.getClosestMatchingLanguage(nextLanguageTag, languages);
  }
}
