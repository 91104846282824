import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

export const DEFAULT_SPACING = 2;
export const SPACING_BASE_PIXELS = 8;

export const black = "#1d1d1f";
export const green = "#54665a";
export const lightGreen = "#26B941";
export const yellow = "#edff53";
export const lightYellow = "#f0ff71";
export const orange = "#efa334";
export const grey = "#f6f4f2";
export const lightGreyBorder = "rgba(0, 0, 0, 0.23)";
export const white = "#ffffff";

export const darkGrey = "#8a8a8a";
export const lightGrey = "#f6f4f2";
export const mediumGrey = "#CACACA";
export const greyBackground = "#FAFAFA";
export const lightBackground = "#f0f0f0";
export const darkBackground = "#666";
export const greyFont = "#8A8A8A";
export const lightGreyFont = "#b3b3b3";
export const greyBorder = "#CACACA";
export const lightOutlineGrey = "#E6E6E6";

export const disabledGreyBackground = "#F0F0F0";
export const disabledGrey = "#DDDDDD";
export const disabledYellow = "#D8E566";

export const lightRed = "#FF000033";
export const red = "#FF0000";

declare module "@mui/material" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    ghost: true;
    naked: true;
  }
}

const violet = {
  light: "#dbd3ff",
  extraLight: "#e4deff",
  main: "#d2c8ff",
  dark: "#a8a0cc",
  contrastText: black,
};

export const olive = {
  light: "#cccab9",
  extraLight: "#d9d7ca",
  main: "#bfbda7",
  dark: "#605f54",
  contrastText: white,
};

const warning = {
  light: "#FFA77F",
  main: "#FF8A65",
  dark: "#FF6B4A",
};

export const monospaced = Object.freeze({
  fontFamily: "monospace",
});

const contentFont = "GT America Regular, Helvetica, Arial, sans-serif";
const headlineFont = "GT America Condensed Regular, Helvetica, Arial, sans-serif";

export const remergeDesign = {
  palette: {
    primary: olive,
    secondary: violet,
    text: {
      primary: black,
    },
    warning,
  },
  typography: {
    fontFamily: contentFont,
    h1: { fontFamily: headlineFont, fontWeight: 100 },
    h2: { fontFamily: headlineFont, fontWeight: 100 },
    h3: { fontFamily: headlineFont, fontWeight: 100 },
    h4: { fontFamily: headlineFont, fontWeight: 100 },
    h5: { fontFamily: headlineFont, fontWeight: 100 },
    h6: { fontFamily: headlineFont, fontWeight: 100 },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: grey,
          color: black,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 16,
        },
        paperScrollPaper: {
          maxHeight: `calc(100% - ${16 * 2}px)`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: { margin: "16px 0" }, // Add the same style as in expanded mode
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardWarning: {
          backgroundColor: `${warning.light}20`,
        },
      },
    },
  },
};
const theme = createTheme(remergeDesign);

export const blackTheme = createTheme(
  deepmerge(remergeDesign, {
    palette: {
      primary: { ...olive, main: black, light: lightBackground },
      secondary: { ...violet, main: black, light: lightBackground },
      lightYellow: { main: lightYellow },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { size: "large" },
            style: { fontSize: 18, height: 50 },
          },
          {
            props: { size: "medium" },
            style: { fontSize: 16, height: 40 },
          },
          {
            props: { size: "small" },
            style: { fontSize: 14, height: 30 },
          },
          {
            props: { variant: "primary" },
            style: {
              background: black,
              color: white,
              "&:hover": {
                backgroundColor: darkBackground,
                color: lightGreyFont,
              },
            },
          },
          {
            props: { variant: "naked" },
            style: {
              backgroundColor: white,
              color: black,
              border: `1px solid ${lightGreyFont}`,
              "&:hover": {
                backgroundColor: white,
                border: `1px solid ${black}`,
              },
            },
          },
          {
            props: { variant: "ghost" },
            style: {
              backgroundColor: "none",
              borderWidth: 0,
              color: black,
              border: "1px solid transparent",
              "&:hover": {
                backgroundColor: white,
                border: `1px solid ${black}`,
              },
            },
          },
          {
            props: { enabled: false },
            style: {
              backgroundColor: disabledGrey,
              color: white,
              borderWidth: 0,
              "&:disabled": {
                color: white,
              },
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              "&.Mui-focused fieldset": {
                border: `1px solid ${black}`,
              },
            },
          },
        },
      },
    },
  }),
);

export default theme;
