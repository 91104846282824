import {
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  Checkbox as MaterialCheckbox,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useMemo } from "react";
import { black, disabledGrey, disabledGreyBackground, lightBackground, white } from "shared/utilities/theme";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BlackCheckbox = styled(MaterialCheckbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: "4px",
    color: black,
  },
}));

export type Props = CheckboxProps &
  Omit<FormControlLabelProps, "control" | "className"> & {
    filled?: boolean;
    preventDefaultClick?: boolean;
  };

const Checkbox = React.forwardRef(
  ({ label, value, disabled, filled, onChange, preventDefaultClick = false, ...props }: Props, ref) => {
    const labelElement = useMemo(() => <Typography fontSize={12}>{label}</Typography>, [label]);
    return (
      <StyledLabel
        {...props}
        label={labelElement}
        ref={ref}
        onChange={onChange}
        onClick={e => {
          if (preventDefaultClick) e.preventDefault();
        }}
        control={
          <BlackCheckbox
            checked={!!value}
            disabled={disabled}
            size="small"
            sx={
              filled
                ? {
                    "&.Mui-checked": {
                      color: white,
                    },
                  }
                : {}
            }
          />
        }
      />
    );
  },
);

const StyledCheckbox = styled(Checkbox)(({ theme, value, filled, disabled }) => {
  if (!filled) return;

  let backgroundColor = value ? black : lightBackground;
  let color = value ? white : black;

  if (disabled) {
    backgroundColor = disabledGreyBackground;
    color = disabledGrey;
  }

  const baseStyle = {
    height: "30px",
    padding: theme.spacing(1),
    border: `1px solid ${disabled ? disabledGrey : black}`,
    borderRadius: theme.spacing(0.5),
    color,
    backgroundColor,
    "& .MuiTypography-root": {
      fontSize: theme.typography.pxToRem(14),
    },
  };

  return baseStyle;
});

export default StyledCheckbox;
